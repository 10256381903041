@use "../utilities/_base";

.reptile_feedback {
  $this: &;

  margin: 0 0 4px;
  padding: 24px 16px;

  &__banner--hidden,
  &__bannerForm--hidden,
  &__animationBanner--hidden,
  &__container--hidden,
  &__tool--hidden {
    display: none;
  }

  &__tool {
    display: grid;
    height: auto;
    transition: opacity 0.1s;
    opacity: 0;
    cursor: default;
    background-color: base.$pl_color-white100;
    margin: 0;
    padding: 0;

    @include base.breakpoint-medium {
      padding: 24px 16px;
    }

    &--visible {
      opacity: 1;
    }
  }

  .reptile_feedback__questionContainer {
    align-items: center;
    cursor: text;
    display: grid;
    flex-flow: wrap;
    margin-bottom: 16px;
    padding: 0;
    place-items: center;
    text-align: center;
  }

  .reptile_feedback__question {
    font-family: base.$sansFontFamily;
    font-size: map-get(base.$pl_headline50, "font-size");
    font-weight: map-get(base.$pl_headline50, "font-weight");
    line-height: map-get(base.$pl_headline50, "line-height");

    @include base.breakpoint-large {
      font-weight: map-get(base.$pl_headline100, "font-weight");
      line-height: map-get(base.$pl_headline100, "line-height");
      font-size: map-get(base.$pl_headline100, "font-size");
    }

  }

  .reptile_feedback__smileys {
    margin-top: 16px;
    align-self: center;
    font-size: 0 !important;
    justify-content: center;
    display: flex;

    svg {
      cursor: pointer;
      height: 48px;
      width: 48px;
      margin: 0 6px;
      vertical-align: top;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      @include base.breakpoint-large {
        margin: 0 8px;
      }

      @include base.breakpoint-medium {
        padding-top: 0;
        margin: 0 6px;
      }

      &.pl_icon_angry {
        &#{$this}__smiley--active,
        &:hover {
          fill: #DC3545;
        }
      }

      &.pl_icon_dislike {
        &#{$this}__smiley--active,
        &:hover {
          fill: #DC3545;
        }
      }

      &.pl_icon_negative {
        &#{$this}__smiley--active,
        &:hover {
          fill: #EF9524;
        }
      }

      &.pl_icon_neutral {
        &#{$this}__smiley--active,
        &:hover {
          fill: #FFD400;
        }
      }

      &.pl_icon_positive {
        &#{$this}__smiley--active,
        &:hover {
          fill: #9DC918;
        }
      }

      &.pl_icon_happy {
        &#{$this}__smiley--active,
        &:hover {
          fill: #008002;
        }
      }

      &.pl_icon_like-fingers {
        &#{$this}__smiley--active,
        &:hover {
          fill: #008002;
        }
      }
    }
  }

  /*                  */
  &__message {
    display: none;
    margin-top: 16px;

    &--visible {
      display: block;
    }

    &Buttons {
      display: flex;
      gap: 16px;

      @include base.breakpoint-large {
        justify-content: right;
      }
    }
  }

  &--mobile {
    display: flex;

    &.reptile_feedback__negative,
    &.reptile_feedback__positive {
      display: none;

      &--visible {
        display: flex;
      }
    }

    @include base.breakpoint-large {
      display: none;

      &.reptile_feedback__negative,
      &.reptile_feedback__positive {
        display: none;
      }
    }
  }

  &--desktop {
    display: none;

    &.reptile_feedback__negative,
    &.reptile_feedback__positive {
      display: none;
    }

    @include base.breakpoint-large {
      display: flex;

      &.reptile_feedback__negative,
      &.reptile_feedback__positive {
        display: none;

        &--visible {
          display: flex;
        }
      }
    }
  }

  .pl_media-object {
    border-radius: 0;
  }

  /*        */
  &--animation {
    position: relative;
    height: 150px;
    overflow: hidden;
    transition: height .4s ease;

    @include base.breakpoint-medium {
      height: 200px;
    }

    & #{$this}__tool {
      position: relative;
      top: 0;
      transition: top .4s ease;
    }

    &Message {
      height: 380px;

      @include base.breakpoint-large {
        height: 330px;
      }

      & #{$this}__tool {
        top: -140px;
      }
    }

    &Banner {
      height: 100px;
    }
  }
}
